.Game-Holder {
  display: flex;
  justify-content: center;
  margin: 2rem;
  width: 100%;
  height: 100%;
  
}

.App{
  width: 100%;
  height: 100%;
}