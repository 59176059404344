.Inject-Button {

    width: 100%;
    height: 100%;
    display: flex;
    justify-self: center;
    opacity: 5%;
}

.Reset-Button{
    width: 100%;
    height: 80%;
    grid-column: 5/5;
    grid-row:7/7;
    justify-self: center;
    align-self: center;
    
}

.Inject-Button:hover:enabled {
    opacity: 100%;
}

.Status-Line{
    grid-row: 7/7;
    grid-column: 1/4;
    align-self: center;
}

.Occupied-Square {
    border: 0.2rem solid;
    border-radius: 50%;
    width: 50%;
    height: 50%;
    
}

.Occupied-Square.Player-1 {
    background: #bb3e03;
}

.Occupied-Square.Player-2 {
    background: #006d77;
}


.Game-Square {
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: black;
}

.Game-Board {
    width: 35rem;
    height: 35rem;
    display: grid;
    grid-template-rows: 2.5rem repeat(5, 5rem) 4rem;
    grid-template-columns: repeat(5,5rem);
    gap: 0.5rem;
}


b.Player-1{
    color: #bb3e03;
}

b.Player-2{
    color: #006d77;
}